import React from 'react';
import Countdown from 'react-countdown-now';
import TimerContext from '../../../shared/timer-context';

const CountdownTimer = () => {
  return (
    <TimerContext.Consumer>
      {(value) =>
        <Countdown date={Date.now() + value.timeLeft} onTick={value.ticker} renderer={value.renderer} intervalDelay={1000} />
      }
    </TimerContext.Consumer>
)
};
export default CountdownTimer;