import React from 'react';
const Jackpots = ({ width = '52', className = '', height = '46', viewBox='0 0 52 46' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path id="svg-jackpots-b" d="M37.362 31.39a2.384 2.384 0 012.383 2.395c0 .55-.45.998-1.002.998a1 1 0 01-1.002-.998.388.388 0 00-.383-.397H2.387a.39.39 0 00-.383.397c0 .55-.45.998-1.002.998A1 1 0 010 33.785a2.396 2.396 0 012.39-2.396zm0-25.393a2.396 2.396 0 012.39 2.396v18a2.396 2.396 0 01-2.39 2.396H2.39A2.396 2.396 0 010 26.393v-18a2.396 2.396 0 012.39-2.396zm0 1.996H2.39a.39.39 0 00-.382.396V26.39c0 .22.17.397.382.397h34.972a.39.39 0 00.382-.397v-18a.388.388 0 00-.382-.397zM15.25 13v1.596L12.2 23H9.775l2.974-7.76H9V13h6.25zm8 0v1.596L20.2 23h-2.424l2.974-7.76H17V13h6.25zm8 0v1.596L28.2 23h-2.424l2.974-7.76H25V13h6.25zm7.5-13a1 1 0 011.002.998 2.396 2.396 0 01-2.39 2.396H2.39A2.391 2.391 0 01.003.998C.003.448.453 0 1.005 0a1 1 0 011.003.998c0 .22.17.397.382.397h34.975a.39.39 0 00.382-.397c0-.55.45-.998 1.002-.998z"/>
      <filter id="svg-jackpots-a" width="150.3%" height="157.5%" x="-25.2%" y="-28.7%" filterUnits="objectBoundingBox">
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3"/>
        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.839215686 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feMorphology in="SourceAlpha" operator="dilate" radius=".5" result="shadowSpreadOuter2"/>
        <feOffset in="shadowSpreadOuter2" result="shadowOffsetOuter2"/>
        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="2"/>
        <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0.839215686 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="shadowMatrixOuter2"/>
        </feMerge>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(6 6)">
      <use fill="#000" filter="url(#svg-jackpots-a)" href="#svg-jackpots-b"/>
      <use fill="#FFCDCD" href="#svg-jackpots-b"/>
    </g>
  </svg>;

  export default Jackpots;