import React from 'react';
const Deposit = ({ width = '89', className = '', height = '94', viewBox='0 0 89 94' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <filter id="svg-deposit-a" width="232.5%" height="173.8%" x="-66.3%" y="-36.9%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceGraphic" stdDeviation="7.303"/>
      </filter>
      <path id="svg-deposit-c" d="M34.375 59.41h-5.793a2.154 2.154 0 01-2.154-2.155v-2.557a16.274 16.274 0 01-9.973-8.486 5.498 5.498 0 01-.19-4.307 5.73 5.73 0 013.123-3.259c2.935-1.277 6.421 0 7.769 2.806a4.77 4.77 0 004.326 2.637c2.61 0 4.735-1.997 4.735-4.452 0-.763 0-2.354-6.753-4.735-3.97-1.397-14.505-5.108-14.505-15.434 0-.308 0-.616.03-.923a1.541 1.541 0 113.078.181c0 .25-.025.502-.025.745 0 8.147 8.41 11.11 12.45 12.532 7.31 2.575 8.809 4.923 8.809 7.637 0 4.15-3.505 7.53-7.813 7.53a7.87 7.87 0 01-7.104-4.388 2.895 2.895 0 00-3.763-1.311 2.69 2.69 0 00-1.471 1.514 2.462 2.462 0 00.086 1.92 13.258 13.258 0 008.775 7.061 1.988 1.988 0 011.499 1.93v2.46h3.954v-2.46a1.99 1.99 0 011.501-1.927c5.868-1.495 9.966-6.563 9.966-12.329a10.388 10.388 0 00-.307-2.597 1.538 1.538 0 112.978-.77c.28 1.1.421 2.232.419 3.367 0 6.902-4.674 12.988-11.468 15.077v2.554a2.154 2.154 0 01-2.179 2.138zm8.431-26.77c-.404 0-.792-.16-1.08-.443-2.932-2.89-7.2-4.394-9.252-5.114-6.422-2.258-8.806-4.335-8.806-7.63 0-4.151 3.504-7.53 7.812-7.53a7.84 7.84 0 016.92 4.034 2.91 2.91 0 003.837 1.126 2.662 2.662 0 001.378-1.575 2.46 2.46 0 00-.19-1.917 13.345 13.345 0 00-8.542-6.493 1.874 1.874 0 01-1.428-1.821v-2.2h-3.953v2.206c0 .862-.588 1.614-1.425 1.822a13.4 13.4 0 00-8.514 6.443 1.539 1.539 0 11-2.695-1.486 16.45 16.45 0 019.557-7.693V2.154c0-1.19.964-2.154 2.153-2.154h5.794c1.19 0 2.154.964 2.154 2.154V4.36a16.388 16.388 0 019.594 7.754 5.483 5.483 0 01.422 4.308 5.68 5.68 0 01-2.942 3.403 6.01 6.01 0 01-7.908-2.41 4.77 4.77 0 00-4.218-2.427c-2.612 0-4.736 1.997-4.736 4.452 0 .822 0 2.354 6.77 4.732 2.261.797 6.966 2.462 10.387 5.828a1.538 1.538 0 01-1.08 2.634l-.009.006z"/>
      <filter id="svg-deposit-b" width="142.3%" height="123.6%" x="-21.2%" y="-8.4%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.399776005 0"/>
      </filter>
      <filter id="svg-deposit-d" width="169.5%" height="146.9%" x="-34.8%" y="-23.5%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceGraphic" stdDeviation="5"/>
      </filter>
      <path id="svg-deposit-f" d="M16.84 64.693a5.849 5.849 0 01-5.84-5.84v-5.86a1.518 1.518 0 113.037 0v5.86a2.806 2.806 0 002.803 2.804h31.477a2.806 2.806 0 002.802-2.803v-5.862a1.518 1.518 0 113.037 0v5.862a5.849 5.849 0 01-5.821 5.84H16.84zm34.298-51.26V6.572a2.806 2.806 0 00-2.803-2.803H16.84a2.806 2.806 0 00-2.803 2.803v6.861a1.518 1.518 0 11-3.037 0V6.572a5.849 5.849 0 015.84-5.84h31.477a5.849 5.849 0 015.84 5.84v6.861a1.52 1.52 0 01-1.501 1.519c-.839 0-1.518-.68-1.518-1.519z"/>
      <filter id="svg-deposit-e" width="132.4%" height="121.9%" x="-16.2%" y="-7.8%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
      </filter>
    </defs>
    <g fill="none">
      <path fill="#FFAB00" d="M34.375 59.41h-5.793a2.154 2.154 0 01-2.154-2.155v-2.557a16.274 16.274 0 01-9.973-8.486 5.498 5.498 0 01-.19-4.307 5.73 5.73 0 013.123-3.259c2.935-1.277 6.421 0 7.769 2.806a4.77 4.77 0 004.326 2.637c2.61 0 4.735-1.997 4.735-4.452 0-.763 0-2.354-6.753-4.735-3.97-1.397-14.505-5.108-14.505-15.434 0-.308 0-.616.03-.923a1.541 1.541 0 113.078.181c0 .25-.025.502-.025.745 0 8.147 8.41 11.11 12.45 12.532 7.31 2.575 8.809 4.923 8.809 7.637 0 4.15-3.505 7.53-7.813 7.53a7.87 7.87 0 01-7.104-4.388 2.895 2.895 0 00-3.763-1.311 2.69 2.69 0 00-1.471 1.514 2.462 2.462 0 00.086 1.92 13.258 13.258 0 008.775 7.061 1.988 1.988 0 011.499 1.93v2.46h3.954v-2.46a1.99 1.99 0 011.501-1.927c5.868-1.495 9.966-6.563 9.966-12.329a10.388 10.388 0 00-.307-2.597 1.538 1.538 0 112.978-.77c.28 1.1.421 2.232.419 3.367 0 6.902-4.674 12.988-11.468 15.077v2.554a2.154 2.154 0 01-2.179 2.138zm8.431-26.77c-.404 0-.792-.16-1.08-.443-2.932-2.89-7.2-4.394-9.252-5.114-6.422-2.258-8.806-4.335-8.806-7.63 0-4.151 3.504-7.53 7.812-7.53a7.84 7.84 0 016.92 4.034 2.91 2.91 0 003.837 1.126 2.662 2.662 0 001.378-1.575 2.46 2.46 0 00-.19-1.917 13.345 13.345 0 00-8.542-6.493 1.874 1.874 0 01-1.428-1.821v-2.2h-3.953v2.206c0 .862-.588 1.614-1.425 1.822a13.4 13.4 0 00-8.514 6.443 1.539 1.539 0 11-2.695-1.486 16.45 16.45 0 019.557-7.693V2.154c0-1.19.964-2.154 2.153-2.154h5.794c1.19 0 2.154.964 2.154 2.154V4.36a16.388 16.388 0 019.594 7.754 5.483 5.483 0 01.422 4.308 5.68 5.68 0 01-2.942 3.403 6.01 6.01 0 01-7.908-2.41 4.77 4.77 0 00-4.218-2.427c-2.612 0-4.736 1.997-4.736 4.452 0 .822 0 2.354 6.77 4.732 2.261.797 6.966 2.462 10.387 5.828a1.538 1.538 0 01-1.08 2.634l-.009.006z" filter="url(#a)" transform="translate(12 17)"/>
      <g transform="translate(12 17)">
        <use fill="#000" filter="url(#svg-deposit-b)" href="#c"/>
        <use fill="#FFAB00" href="#svg-deposit-c"/>
      </g>
      <path fill="#FEFE00" d="M46.375 75.588h-5.793a1.465 1.465 0 01-1.465-1.462V71.05a15.625 15.625 0 01-10.04-8.264 4.81 4.81 0 01-.17-3.782 5.046 5.046 0 012.751-2.868c2.6-1.129 5.68-.021 6.871 2.462a5.465 5.465 0 004.951 3.028c2.99 0 5.425-2.305 5.425-5.142 0-.994 0-2.846-7.216-5.388-4.2-1.48-14.04-4.947-14.04-14.769 0-.29 0-.588.028-.883a.846.846 0 011.69.102c0 .261-.025.526-.025.781 0 8.64 9.049 11.828 12.923 13.188 6.926 2.44 8.344 4.557 8.344 6.984 0 3.77-3.194 6.834-7.117 6.834a7.163 7.163 0 01-6.461-4 3.594 3.594 0 00-4.668-1.646 3.348 3.348 0 00-1.846 1.905 3.12 3.12 0 00.108 2.461 13.954 13.954 0 009.23 7.434 1.3 1.3 0 01.979 1.256v3.16h5.338v-3.154c0-.595.405-1.114.982-1.259 6.172-1.572 10.486-6.917 10.486-12.997a11.175 11.175 0 00-.342-2.769.846.846 0 011.637-.425c.268 1.044.401 2.117.397 3.194 0 6.77-4.701 12.705-11.467 14.56v3.077a1.465 1.465 0 01-1.49 1.459zm8.431-26.77a.837.837 0 01-.594-.243c-3.043-3-7.409-4.538-9.507-5.277-6.948-2.461-8.345-4.486-8.345-6.984 0-3.77 3.194-6.834 7.12-6.834a7.154 7.154 0 016.314 3.67 3.606 3.606 0 004.75 1.416 3.32 3.32 0 001.73-1.988 3.114 3.114 0 00-.243-2.461 14.034 14.034 0 00-8.982-6.83 1.188 1.188 0 01-.901-1.152v-2.892h-5.326v2.899c0 .544-.373 1.018-.902 1.147a14.102 14.102 0 00-8.95 6.77.847.847 0 01-1.484-.819 15.794 15.794 0 019.643-7.505V19a1.465 1.465 0 011.465-1.462h5.794c.807 0 1.463.654 1.464 1.462v2.73a15.714 15.714 0 019.677 7.565 4.803 4.803 0 01.373 3.776 5.028 5.028 0 01-2.591 2.994 5.314 5.314 0 01-6.991-2.123 5.458 5.458 0 00-4.828-2.788c-2.994 0-5.427 2.308-5.427 5.141 0 1.065 0 2.847 7.215 5.388 2.215.782 6.822 2.403 10.132 5.668a.846.846 0 01-.594 1.449l-.012.018z" opacity=".8"/>
      <path fill="#EB2D62" d="M16.84 64.693a5.849 5.849 0 01-5.84-5.84v-5.86a1.518 1.518 0 113.037 0v5.86a2.806 2.806 0 002.803 2.804h31.477a2.806 2.806 0 002.802-2.803v-5.862a1.518 1.518 0 113.037 0v5.862a5.849 5.849 0 01-5.821 5.84H16.84zm34.298-51.26V6.572a2.806 2.806 0 00-2.803-2.803H16.84a2.806 2.806 0 00-2.803 2.803v6.861a1.518 1.518 0 11-3.037 0V6.572a5.849 5.849 0 015.84-5.84h31.477a5.849 5.849 0 015.84 5.84v6.861a1.52 1.52 0 01-1.501 1.519c-.839 0-1.518-.68-1.518-1.519z" filter="url(#d)" transform="rotate(90 30.078 47.213)"/>
      <g transform="rotate(90 30.078 47.213)">
        <use fill="#000" filter="url(#svg-deposit-e)" href="#svg-deposit-f"/>
        <use fill="#EB2D62" href="#svg-deposit-f"/>
      </g>
      <path fill="#FF6376" d="M13.604 34.291a5.162 5.162 0 015.162-5.162h5.861a.835.835 0 010 1.67h-5.861a3.49 3.49 0 00-3.492 3.492v31.477a3.49 3.49 0 003.486 3.486h5.861a.835.835 0 010 1.67H18.76a5.162 5.162 0 01-5.156-5.138V34.291zm49.741 35.816c0-.46.374-.835.835-.835h6.862a3.49 3.49 0 003.486-3.486V34.291a3.49 3.49 0 00-3.486-3.486H64.18a.835.835 0 010-1.67h6.862a5.162 5.162 0 015.162 5.162v31.477a5.162 5.162 0 01-5.162 5.163H64.18a.835.835 0 01-.835-.83z"/>
      <path fill="#FF8E8F" d="M14.6 65.235V33.758a4.628 4.628 0 014.624-4.625h5.861a.304.304 0 010 .608h-5.861a4.02 4.02 0 00-4.018 4.011v31.477a4.02 4.02 0 004.018 4.018h5.861a.304.304 0 010 .607h-5.861a4.628 4.628 0 01-4.625-4.619zm49.738 4.321c0-.167.136-.303.303-.303h6.862a4.02 4.02 0 004.017-4.018V33.758a4.02 4.02 0 00-4.017-4.017H64.64a.304.304 0 110-.608h6.862a4.628 4.628 0 014.625 4.625v31.477a4.628 4.628 0 01-4.625 4.625H64.64a.304.304 0 01-.312-.304h.009z"/>
      <path fill="#FEFE00" d="M46.375 74.902h-5.793a.923.923 0 01-.924-.924v-3.384a.145.145 0 00-.11-.142 15.098 15.098 0 01-9.982-8.052 4.274 4.274 0 01-.15-3.363 4.52 4.52 0 012.46-2.56 4.76 4.76 0 016.176 2.215 6.012 6.012 0 005.437 3.336c3.29 0 5.963-2.548 5.963-5.683 0-1.56-.584-3.431-7.575-5.896-4.092-1.443-13.68-4.818-13.68-14.274 0-.28 0-.569.028-.852a.308.308 0 01.326-.29.308.308 0 01.286.324c0 .274-.025.55-.025.818 0 9.019 9.299 12.308 13.271 13.696 7.37 2.594 8 4.704 8 6.461 0 3.474-2.954 6.299-6.581 6.299a6.63 6.63 0 01-5.994-3.693 4.135 4.135 0 00-5.366-1.91 3.914 3.914 0 00-2.13 2.212 3.662 3.662 0 00.126 2.88 14.498 14.498 0 009.582 7.726.757.757 0 01.572.736v3.384c0 .17.138.308.308.308h5.794c.17 0 .308-.138.308-.308v-3.384c0-.35.237-.653.575-.739 6.412-1.634 10.892-7.19 10.892-13.52.002-.98-.119-1.955-.36-2.905a.308.308 0 11.597-.153c.254 1 .38 2.027.379 3.058 0 6.61-4.671 12.415-11.357 14.117a.148.148 0 00-.111.142v3.384a.923.923 0 01-.942.936zm8.431-26.77a.29.29 0 01-.215-.089c-3.126-3.077-7.573-4.646-9.708-5.4-7.385-2.594-8-4.615-8-6.461 0-3.474 2.95-6.299 6.582-6.299a6.612 6.612 0 015.846 3.385 4.154 4.154 0 005.464 1.64 3.862 3.862 0 002.003-2.308 3.658 3.658 0 00-.283-2.877 14.578 14.578 0 00-9.326-7.095.646.646 0 01-.492-.616V18.88a.308.308 0 00-.308-.308h-5.787a.308.308 0 00-.308.308v3.126a.646.646 0 01-.492.616 14.643 14.643 0 00-9.293 7.043.308.308 0 11-.538-.299 15.252 15.252 0 019.683-7.341.034.034 0 00.024-.031V18.88c0-.51.414-.923.924-.923h5.793c.51 0 .923.413.923.923V22a.033.033 0 00.025.03 15.2 15.2 0 019.72 7.4 4.27 4.27 0 01.33 3.355 4.477 4.477 0 01-2.318 2.673 4.772 4.772 0 01-6.28-1.895 5.99 5.99 0 00-5.298-3.077c-3.29 0-5.966 2.55-5.966 5.683 0 1.631.584 3.431 7.575 5.896 2.179.766 6.708 2.363 9.932 5.538.12.12.12.314 0 .434a.308.308 0 01-.212.101v-.006z"/>
    </g>
  </svg>;

  export default Deposit;