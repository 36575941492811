import React from 'react';
const Register = ({ width = '81', className = '', height = '84', viewBox='0 0 81 84' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <filter id="svg-register-a" width="153.1%" height="211.7%" x="-26.6%" y="-55.8%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceGraphic" stdDeviation="5"/>
      </filter>
      <path id="svg-register-c" d="M41.587 59.772c-8.221-.008-14.884-6.671-14.893-14.892a14.68 14.68 0 012.249-7.875 8.563 8.563 0 017.348-4.088c2.438 0 4.302 1.186 5.305 1.827 1.124-.694 3.202-1.979 6.002-1.818 4.298.239 6.648 4.026 7.265 5.19a14.4 14.4 0 011.62 6.764 14.827 14.827 0 01-1.023 5.431 1.548 1.548 0 01-2.883-1.13c.536-1.37.81-2.83.809-4.301a11.3 11.3 0 00-1.255-5.305c-.876-1.641-2.573-3.44-4.707-3.558-1.836-.102-3.264.78-4.208 1.365-.62.375-1.04.645-1.63.645-.588 0-1.04-.282-1.65-.67-.839-.535-2.106-1.343-3.645-1.343a5.487 5.487 0 00-4.731 2.647 11.579 11.579 0 00-1.769 6.219 11.799 11.799 0 0019.677 8.779 1.55 1.55 0 112.072 2.304 14.883 14.883 0 01-9.953 3.81zm-26.691 0C6.674 59.764.01 53.102 0 44.88a14.68 14.68 0 012.249-7.875 8.572 8.572 0 017.348-4.088c2.437 0 4.305 1.186 5.308 1.827 1.121-.694 3.2-1.979 5.999-1.818a7.497 7.497 0 013.601 1.17 1.548 1.548 0 11-1.638 2.627 4.497 4.497 0 00-2.137-.706c-1.833-.102-3.26.78-4.208 1.365-.604.375-1.04.645-1.626.645-.585 0-1.044-.282-1.65-.67-.84-.535-2.11-1.343-3.649-1.343a5.487 5.487 0 00-4.732 2.647 11.579 11.579 0 00-1.768 6.219 11.799 11.799 0 0019.841 8.63 1.548 1.548 0 112.112 2.264 14.834 14.834 0 01-10.154 3.998zm19.996-10.126a1.548 1.548 0 01-1.41-.908 7.9 7.9 0 01.484-7.432 1.549 1.549 0 112.616 1.657 4.673 4.673 0 00-.712 2.514 4.766 4.766 0 00.43 1.988 1.548 1.548 0 01-1.408 2.193v-.012zm-26.98 0a1.548 1.548 0 01-1.408-.908 7.878 7.878 0 01.483-7.432A1.548 1.548 0 119.6 42.944a4.698 4.698 0 00-.712 2.515 4.766 4.766 0 00.43 1.988 1.548 1.548 0 01-.764 2.05c-.2.095-.42.146-.641.149z"/>
      <filter id="svg-register-b" width="124.8%" height="152.1%" x="-12.4%" y="-18.6%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
      </filter>
      <filter id="svg-register-d" width="170.9%" height="195.1%" x="-35.4%" y="-47.5%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceGraphic" stdDeviation="5"/>
      </filter>
      <path id="svg-register-f" d="M41.587 31.56a1.548 1.548 0 01-1.496-1.148l-5.125-19.176c-1.517 2.945-4.478 6.401-10.253 8.866-10.718 4.574-21.929 4.4-22.4 4.389A1.548 1.548 0 01.878 22.45a45.433 45.433 0 014.137-8.498C8.73 7.957 13.294 3.776 18.231 1.875 26.202-1.222 30.928.435 31.138.506a1.549 1.549 0 11-1.059 2.911c-.021 0-3.945-1.288-10.73 1.344-5.416 2.103-9.334 7.023-11.666 10.777a45.9 45.9 0 00-3.038 5.815c3.741-.185 11.517-.953 18.854-4.084 9.984-4.264 9.962-11.746 9.962-11.824a1.548 1.548 0 013.044-.427l6.578 24.61a1.548 1.548 0 01-1.094 1.895 1.654 1.654 0 01-.402.037zm-25.78-.13a1.548 1.548 0 01-1.4-2.183l1.566-3.484a1.548 1.548 0 112.825 1.267l-1.564 3.487c-.254.565-.82.924-1.44.913h.012z"/>
      <filter id="svg-register-e" width="133.1%" height="144.4%" x="-16.5%" y="-15.8%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
      </filter>
    </defs>
    <g fill="none">
      <path fill="#EB2D62" d="M41.587 59.772c-8.221-.008-14.884-6.671-14.893-14.892a14.68 14.68 0 012.249-7.875 8.563 8.563 0 017.348-4.088c2.438 0 4.302 1.186 5.305 1.827 1.124-.694 3.202-1.979 6.002-1.818 4.298.239 6.648 4.026 7.265 5.19a14.4 14.4 0 011.62 6.764 14.827 14.827 0 01-1.023 5.431 1.548 1.548 0 01-2.883-1.13c.536-1.37.81-2.83.809-4.301a11.3 11.3 0 00-1.255-5.305c-.876-1.641-2.573-3.44-4.707-3.558-1.836-.102-3.264.78-4.208 1.365-.62.375-1.04.645-1.63.645-.588 0-1.04-.282-1.65-.67-.839-.535-2.106-1.343-3.645-1.343a5.487 5.487 0 00-4.731 2.647 11.579 11.579 0 00-1.769 6.219 11.799 11.799 0 0019.677 8.779 1.55 1.55 0 112.072 2.304 14.883 14.883 0 01-9.953 3.81zm-26.691 0C6.674 59.764.01 53.102 0 44.88a14.68 14.68 0 012.249-7.875 8.572 8.572 0 017.348-4.088c2.437 0 4.305 1.186 5.308 1.827 1.121-.694 3.2-1.979 5.999-1.818a7.497 7.497 0 013.601 1.17 1.548 1.548 0 11-1.638 2.627 4.497 4.497 0 00-2.137-.706c-1.833-.102-3.26.78-4.208 1.365-.604.375-1.04.645-1.626.645-.585 0-1.044-.282-1.65-.67-.84-.535-2.11-1.343-3.649-1.343a5.487 5.487 0 00-4.732 2.647 11.579 11.579 0 00-1.768 6.219 11.799 11.799 0 0019.841 8.63 1.548 1.548 0 112.112 2.264 14.834 14.834 0 01-10.154 3.998zm19.996-10.126a1.548 1.548 0 01-1.41-.908 7.9 7.9 0 01.484-7.432 1.549 1.549 0 112.616 1.657 4.673 4.673 0 00-.712 2.514 4.766 4.766 0 00.43 1.988 1.548 1.548 0 01-1.408 2.193v-.012zm-26.98 0a1.548 1.548 0 01-1.408-.908 7.878 7.878 0 01.483-7.432A1.548 1.548 0 119.6 42.944a4.698 4.698 0 00-.712 2.515 4.766 4.766 0 00.43 1.988 1.548 1.548 0 01-.764 2.05c-.2.095-.42.146-.641.149z" filter="url(#a)" transform="translate(12 12)"/>
      <g transform="translate(12 12)">
        <use fill="#000" filter="url(#svg-register-b)" href="#svg-register-c"/>
        <use fill="#EB2D62" href="#svg-register-c"/>
      </g>
      <path fill="#FFAB00" d="M41.587 31.56a1.548 1.548 0 01-1.496-1.148l-5.125-19.176c-1.517 2.945-4.478 6.401-10.253 8.866-10.718 4.574-21.929 4.4-22.4 4.389A1.548 1.548 0 01.878 22.45a45.433 45.433 0 014.137-8.498C8.73 7.957 13.294 3.776 18.231 1.875 26.202-1.222 30.928.435 31.138.506a1.549 1.549 0 11-1.059 2.911c-.021 0-3.945-1.288-10.73 1.344-5.416 2.103-9.334 7.023-11.666 10.777a45.9 45.9 0 00-3.038 5.815c3.741-.185 11.517-.953 18.854-4.084 9.984-4.264 9.962-11.746 9.962-11.824a1.548 1.548 0 013.044-.427l6.578 24.61a1.548 1.548 0 01-1.094 1.895 1.654 1.654 0 01-.402.037zm-25.78-.13a1.548 1.548 0 01-1.4-2.183l1.566-3.484a1.548 1.548 0 112.825 1.267l-1.564 3.487c-.254.565-.82.924-1.44.913h.012z" filter="url(#d)" transform="translate(12 12)"/>
      <g transform="translate(12 12)">
        <use fill="#000" filter="url(#svg-register-e)" href="#svg-register-f"/>
        <use fill="#FFAB00" href="#svg-register-f"/>
      </g>
      <path fill="#FF6376" d="M53.587 70.976c-7.837-.01-14.187-6.362-14.196-14.199a13.983 13.983 0 012.14-7.5 7.82 7.82 0 016.76-3.766c2.24 0 3.992 1.121 4.937 1.722l.365.226.36-.22c1.055-.65 3.025-1.858 5.604-1.718 3.936.22 6.117 3.747 6.693 4.824a13.678 13.678 0 011.548 6.432 14.168 14.168 0 01-.972 5.178.853.853 0 11-1.59-.62c.57-1.451.862-2.998.859-4.558a11.963 11.963 0 00-1.338-5.63c-.963-1.811-2.858-3.793-5.265-3.926-2.047-.115-3.657.876-4.617 1.468-.542.337-.87.539-1.26.539s-.728-.211-1.276-.558c-.852-.545-2.28-1.455-4.026-1.455a6.175 6.175 0 00-5.323 2.973 12.294 12.294 0 00-1.858 6.59 12.495 12.495 0 0020.84 9.29.852.852 0 111.137 1.266 14.18 14.18 0 01-9.522 3.642zm-26.691 0c-7.838-.008-14.19-6.36-14.2-14.199a13.983 13.983 0 012.141-7.5 7.826 7.826 0 016.763-3.766c2.24 0 3.995 1.121 4.937 1.722l.365.226c.108-.062.242-.145.362-.22 1.053-.65 3.023-1.858 5.605-1.718a6.835 6.835 0 013.27 1.065.852.852 0 11-.9 1.446 5.15 5.15 0 00-2.478-.811c-2.053-.115-3.657.876-4.614 1.468-.545.337-.873.539-1.26.539-.388 0-.728-.211-1.276-.558-.855-.545-2.28-1.455-4.026-1.455a6.166 6.166 0 00-5.32 2.973 12.297 12.297 0 00-1.877 6.59 12.495 12.495 0 0021.015 9.141.852.852 0 011.16 1.239 14.146 14.146 0 01-9.667 3.818zm19.996-10.13a.852.852 0 01-.775-.498 7.2 7.2 0 01.437-6.782.852.852 0 011.44.91 5.364 5.364 0 00-.82 2.887 5.438 5.438 0 00.492 2.279.852.852 0 01-.774 1.205zm-26.98 0a.855.855 0 01-.777-.498 7.163 7.163 0 01-.647-2.985 7.06 7.06 0 011.087-3.797.852.852 0 011.437.91 5.487 5.487 0 00-.31 5.166.852.852 0 01-.774 1.205h-.015z"/>
      <path fill="#FEFE00" d="M53.587 42.762a.852.852 0 01-.82-.62l-5.64-21.092c-1.102 3.097-3.846 7.401-10.687 10.322-10.582 4.518-21.646 4.335-22.114 4.335a.852.852 0 01-.787-1.124 44.563 44.563 0 014.066-8.361c3.63-5.866 8.083-9.95 12.877-11.811 7.742-3.001 12.232-1.431 12.418-1.363a.852.852 0 11-.582 1.601c-.034 0-4.147-1.397-11.22 1.35-9.033 3.509-14.075 14.416-15.512 17.98 3.162-.087 11.942-.663 20.185-4.184 10.356-4.419 10.39-12.151 10.387-12.477a.852.852 0 011.672-.232l6.596 24.604a.852.852 0 01-.84 1.072zm-25.78-.127a.852.852 0 01-.774-1.202l1.547-3.487a.852.852 0 011.549.7l-1.567 3.484a.852.852 0 01-.768.505h.012z" opacity=".5"/>
      <path fill="#FF8E8F" d="M53.587 70.31c-7.539-.008-13.647-6.118-13.654-13.656-.013-2.55.7-5.05 2.056-7.21a7.287 7.287 0 016.302-3.511c2.081 0 3.686 1.022 4.646 1.635a3.8 3.8 0 00.662.377 5.29 5.29 0 00.638-.365c1.004-.62 2.874-1.774 5.29-1.641 2.904.16 5.128 2.45 6.243 4.537a13.14 13.14 0 011.474 6.178 13.626 13.626 0 01-.93 4.982.31.31 0 11-.575-.226c.592-1.516.894-3.129.891-4.756a12.523 12.523 0 00-1.4-5.884c-1.034-1.942-3.096-4.066-5.731-4.212-2.224-.127-3.918.93-4.93 1.549-.477.31-.74.458-.976.458-.235 0-.502-.17-.985-.477-.9-.573-2.412-1.548-4.31-1.548a6.686 6.686 0 00-5.779 3.223 12.827 12.827 0 00-1.96 6.878 13.037 13.037 0 0021.745 9.702.31.31 0 01.412.462 13.626 13.626 0 01-9.13 3.505zm-26.691 0c-7.54-.008-13.648-6.118-13.657-13.656-.012-2.55.702-5.05 2.056-7.21a7.296 7.296 0 016.305-3.511c2.081 0 3.689 1.022 4.645 1.635.209.148.432.275.666.377.22-.107.434-.23.638-.365 1.004-.62 2.868-1.774 5.29-1.641a6.345 6.345 0 013.016.985.31.31 0 01-.328.526 5.658 5.658 0 00-2.723-.892c-2.223-.127-3.917.93-4.93 1.549-.48.31-.74.458-.975.458s-.505-.17-.985-.477c-.904-.573-2.412-1.548-4.314-1.548a6.692 6.692 0 00-5.778 3.223 12.827 12.827 0 00-1.96 6.878 13.037 13.037 0 0021.925 9.538.31.31 0 01.42.452 13.592 13.592 0 01-9.311 3.68zm19.996-10.126A.31.31 0 0146.61 60a6.661 6.661 0 01.402-6.268.31.31 0 11.524.332 5.924 5.924 0 00-.904 3.177 5.983 5.983 0 00.541 2.502.31.31 0 01-.154.409.266.266 0 01-.127.03zm-26.98 0a.31.31 0 01-.284-.183 6.602 6.602 0 01-.598-2.759 6.531 6.531 0 011.003-3.509.31.31 0 11.524.332c-.6.95-.915 2.053-.908 3.177a5.983 5.983 0 00.542 2.502.31.31 0 01-.151.409.282.282 0 01-.127.03z"/>
      <path fill="#FEFE00" d="M53.587 42.099a.31.31 0 01-.31-.23l-6.169-23.064c-.557 2.768-2.694 8.432-10.894 11.935-10.476 4.472-21.773 4.292-21.888 4.289a.31.31 0 01-.288-.409c.053-.152 5.323-15.44 16.627-19.82 7.543-2.929 11.857-1.424 12.04-1.359a.31.31 0 01-.214.582c-.04-.015-4.298-1.483-11.6 1.354-5.757 2.236-9.873 7.401-12.31 11.34a45.653 45.653 0 00-3.794 7.696c2.168-.019 12.007-.31 21.2-4.243 10.744-4.59 10.716-12.901 10.716-12.985a.313.313 0 01.62-.086l6.58 24.61a.31.31 0 01-.22.377.232.232 0 01-.096.013zm-25.793-.13a.31.31 0 01-.282-.437l1.567-3.487a.31.31 0 11.567.254l-1.542 3.487a.31.31 0 01-.31.183z"/>
    </g>
  </svg>;

  export default Register;