import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import IcomoonIcon from '../../shared/IcomoonIcon';
import Jackpots from '../../../icons/svg/Jackpots';
import PopupWindow, { invokePopup } from '../../shared/PopupWindow';
import styled from '@emotion/styled';
import { breakpoints, color, Slick } from '../../../utils/style';
import StoreContext from '../../shared/Context/StoreContext';
import SlotGamesTimer from './SlotGamesTimer2';

const NextArrow = props => {
  const { className, onClick } = props;
  return (
    <button className={className} onClick={onClick} aria-label="Next">
      <IcomoonIcon icon="right-thin" color={color.white} size={40} />
    </button>
  );
};

const PrevArrow = props => {
  const { className, onClick } = props;
  return (
    <button className={className} onClick={onClick} aria-label="Previous">
      <IcomoonIcon icon="left-thin" color={color.white} size={40} />
    </button>
  );
};

const SlickWrapper = styled('div')`
  margin: 20px -10px;
  ${Slick}
  .text-block {
    position: static;
    overflow: initial;
    color: ${color.yellow};
  }
  .slick-track {
    display: flex;
    align-items: baseline;
  }

  .slick-dots {
    bottom: -25px;
  }
  
  .slick-prev {
    left: auto;
    right: 60px;
    top: -40px;
  }
  
  .slick-next {
    top: -40px;
    right: 10px;
  }
  
  @media (max-width: ${breakpoints.md}) {
    margin: 10px 0 20px;
    .slick-prev, .slick-next {
      top: -23px;
    }
    .demo-game-slide:nth-of-type(odd) {
      padding-left: 5px;
    }
    .demo-game-slide:nth-of-type(even) {
      padding-right: 5px;
    }
  }

  @media (max-width: ${breakpoints.xxs}) {
    margin: 0 0 20px;
    padding: 0;
    .text-block p {
      max-height: 45px;
    }
  }
`;

const SlickSlide = styled('a')`
  padding: 10px;
  position: relative;
  .play-cta {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 140px;
    margin: -24px 0 0 -70px;
    padding: 15px;
    display: none;
  }
  &:hover,
  &:active {
    text-decoration: none;
    .play-cta {
      display: inline-block;
      z-index: 1;
    }
    img {
      filter: brightness(20%);
    }
  }

  &.demo-game-slide {
    .gatsby-image-wrapper {
      border-radius: 10px;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    padding: 10px;
    .play-cta {
      font-size: 13px;
      padding: 5px 10px;
      width: 100px;
      margin: -14px 0 0 -50px;
    }
    &.jackpot-slide {
      .play-cta {
        margin-top: -20px;
      }
    }
  }
`;

const DemoGamesWrap = styled('div')`
  .h3 { padding-right: 100px;}
  .mobile-rotate {
    display: none;
    text-align: center;
  }
  .waiting {
    position: relative;
    .h3 {
      padding: 0;
      text-align: center;
      max-width: 100%;
      margin-bottom: 5%;
    }
    .bottom-buttons {
      margin-top: 5%;
      .button {
        margin: 10px;
      }
    }
  }
  .login-register-prompt {
    position: absolute;
    top: 28px;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 20px 7%;
    text-align: center;
    background: rgba(0,0,0,0.8);
    z-index: 1;
    
  }
  
  @media (max-width: ${breakpoints.md}) {
    .h3 { margin: 10px 3px -10px 0;}
    .mobile-rotate {
      display: block;
      position: absolute;
      top: 30%;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
    .mobile-rotate + .iframe-container, .login-register-prompt {
      opacity: 0;
      visibility: hidden;
    }
    .waiting {
      .timer-wrap, .h3 {
        position: static;
      }
    }
    .login-register-prompt {
      top: 0;
    }
    .iframe-container {
      width: auto;
      position: static;
      overflow: auto;
      padding: 0;
      iframe {
        position: static;
        height: 98vh;
      }
    }
    
  }
  @media (orientation: landscape) {
    .mobile-rotate { display: none; }
    .mobile-rotate + .iframe-container, .login-register-prompt {
      opacity: 1;
      visibility: visible;
    }
  }
`;



const Demo10Games = ({
                       sliderSettings,
                       demoGameUrl,
                     }) => {
  const [ timerState, setTimerState] = useState({
    startTimer: false
  });

  const demoClickHandler = (e, popupId, gameTitle, gameLink) => {
    invokePopup(e, popupId);
    const gameIframe = document.getElementById('game-home-iframe');
    gameIframe.src = gameLink || demoGameUrl;
    const gamePopupTitle = document.getElementById(popupId + '-title');
    if (gamePopupTitle && gameTitle) {
      gamePopupTitle.innerHTML = gameTitle;
    }
    setTimerState({
      startTimer: true
    });
  };

  const storeValue = useContext(StoreContext);
  const username = storeValue.username;

  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressPage(filter: { slug: { eq: "home-vi" } }) {
            edges {
              node {
                acf {
                  demo_games_10 {
                    alt_text
                    title
                    id
                    acf {
                      slide_url
                      slide_title
                      slide_description
                    }
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 300) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }      
          
        }
      `}
      render={data => {

        return (
          <DemoGamesWrap>
            <h2 className="h3 with-icon">
              <Jackpots/>
              <span>Jackpot và Slot</span>
            </h2>
            {data.allWordpressPage.edges[0].node.acf.demo_games_10.length >
            0 && (
              <SlickWrapper className="demo-games-row">
                <Slider {...sliderSettings}>
                  {data.allWordpressPage.edges[0].node.acf.demo_games_10.map(
                    item => (
                      <SlickSlide
                        className="demo-game-slide"
                        key={item.id}
                        onClick={e =>
                          demoClickHandler(
                            e,
                            'demo-home-popup',
                            item.alt_text,
                            item.acf.slide_url
                          )
                        }
                        href={
                          item.acf && item.acf.slide_url.indexOf('http') !== -1
                            ? item.acf.slide_url
                            : process.env.URL + item.acf.slide_url
                        }
                      >
                        {item.localFile &&
                        item.localFile.childImageSharp.fluid && (
                          <Img
                            fluid={item.localFile.childImageSharp.fluid}
                            alt={item.altText ? item.altText : 'Oppa888'}
                          />
                        )}
                        <span className="play-cta button gradient-button">Chơi thử</span>
                      </SlickSlide>
                    )
                  )}
                </Slider>
              </SlickWrapper>
            )}
            <PopupWindow
              title="เกมตัวอย่าง"
              popupId="demo-home-popup"
              extraClass="wide title-orange custom-game-popup"
            >
              {!username && timerState.startTimer &&
              <SlotGamesTimer startTime={300000}/>
              }
              <div className="mobile-rotate">
                <IcomoonIcon icon="mobile-rotate" color={color.orange} size={80}/>
                <p>Đổi thiết bị</p>
              </div>
              <div className="iframe-container">
                <iframe title="Demo Game" id="game-home-iframe" src="" />
              </div>
            </PopupWindow>
          </DemoGamesWrap>
        );
      }}
    />
  );
};

export default Demo10Games;

Demo10Games.propTypes = {
  sliderSettings: PropTypes.object,
  demoGameUrl: PropTypes.string,
};

Demo10Games.defaultProps = {
  demoGameUrl:
    'https://showcase.playngo.com/Casino/IframedView?pid=2&gid=theswordandthegrail&gameId=376&lang=th&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=&coreweburl=https://showcase.playngo.com/&showpoweredby=True',
  sliderSettings: {
    arrows: true,
    infinite: true,
    slidesPerRow: 5,
    rows: 2,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          dots: true,
          slidesPerRow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          slidesPerRow: 2,
        },
      },
    ],
  },
};
