import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import LazyLoad from 'react-lazyload';
import styled from '@emotion/styled';
import { breakpoints } from '../../../utils/style';

const ImgContainer = styled('div')`
  position: absolute;
  width: 580px;
  left: 45%;
  margin-left: -290px;
  top: -140px;
  z-index: -1;
  opacity: 0.9;
  @media (max-width: ${breakpoints.md}) {
    left: 50%;
    width: 318px;
    margin-left: -189px;
    top: -80px;
  }
`;

const StarsHome = () => {
  return(
  <StaticQuery
    query=
      {graphql`
        query {
          parallax0: file(relativePath: { eq: "parallax/home-stars.png" }) {
            childImageSharp {
              fluid(maxWidth: 580) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
    render={data => (
      <LazyLoad>
        <ImgContainer>
          <Img alt="GCLUB Background 0 Mobile" fluid={data.parallax0.childImageSharp.fluid}/>
        </ImgContainer>
      </LazyLoad>
    )}
  />
)};

export default StarsHome;