import React from 'react';
const Enjoy = ({ width = '91', className = '', height = '81', viewBox='0 0 91 81' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <filter id="svg-enjoy-a" width="140.9%" height="200.5%" x="-20.5%" y="-50.3%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceGraphic" stdDeviation="3.037"/>
      </filter>
      <path id="svg-enjoy-c" d="M16.635 36.556H11.88c-.839 0-1.519-.68-1.519-1.519V19.945c0-.839.68-1.518 1.519-1.518h4.214a5.02 5.02 0 013.92 8.153 5.56 5.56 0 01-3.379 9.976zm-3.234-3.037h3.234a2.527 2.527 0 100-5.05h-3.234v5.05zm0-8.087h2.693a1.986 1.986 0 000-3.969h-2.693v3.97zM52.86 36.528c-.839 0-1.519-.68-1.519-1.518v-2.639c0-.668-.458-3.037-1.913-3.037h-3.316v5.67a1.518 1.518 0 01-3.037 0V19.98c0-.838.68-1.518 1.519-1.518h4.858a5.442 5.442 0 013.477 9.63c.195.253.369.522.52.804a7.914 7.914 0 01.91 3.483v2.64a1.518 1.518 0 01-1.5 1.508zm-3.432-10.224h.043a2.408 2.408 0 100-4.813h-3.34v4.813h3.297zm-10.85 10.054a1.518 1.518 0 01-1.394-.91l-1.882-4.374h-6.393l-1.898 4.38a1.52 1.52 0 11-2.787-1.215l2.168-5.002a1.5 1.5 0 01.243-.555l3.899-9.004a1.716 1.716 0 011.588-1.054c.694 0 1.32.418 1.585 1.06l6.265 14.57a1.518 1.518 0 01-1.394 2.126v-.022zm-8.354-8.33h3.772l-1.88-4.363-1.892 4.364z"/>
      <filter id="svg-enjoy-b" width="131.4%" height="177.2%" x="-15.7%" y="-27.6%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
      </filter>
      <filter id="svg-enjoy-d" width="145.8%" height="193.2%" x="-22.9%" y="-46.6%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceGraphic" stdDeviation="5"/>
      </filter>
      <path id="svg-enjoy-f" d="M61.93 43.583H4.206a3.926 3.926 0 01-3.924-3.924V15.323A3.926 3.926 0 014.206 11.4H61.93a3.926 3.926 0 013.92 3.923V28.94a1.518 1.518 0 01-3.036 0V15.323a.887.887 0 00-.887-.887H4.206a.887.887 0 00-.887.887V39.66c0 .49.397.887.887.887H61.93c.49 0 .887-.397.887-.887v-4.105a1.518 1.518 0 113.037 0v4.105a3.926 3.926 0 01-3.924 3.924z"/>
      <filter id="svg-enjoy-e" width="121.4%" height="143.5%" x="-10.7%" y="-15.5%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
      </filter>
      <filter id="svg-enjoy-g" width="158.6%" height="154.6%" x="-29.3%" y="-27.3%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceGraphic" stdDeviation="5"/>
      </filter>
      <path id="svg-enjoy-i" d="M52.811 55.04H13.316a5.849 5.849 0 01-5.84-5.84v-1.86a1.518 1.518 0 113.037 0v1.86a2.806 2.806 0 002.803 2.803h39.477a2.806 2.806 0 002.803-2.803v-1.86a1.518 1.518 0 113.036 0v1.86a5.849 5.849 0 01-5.82 5.84zm4.321-45.742c-.838 0-1.518-.68-1.518-1.518V5.919a2.806 2.806 0 00-2.803-2.803H13.316a2.806 2.806 0 00-2.803 2.803V7.78a1.518 1.518 0 11-3.037 0V5.919a5.849 5.849 0 015.84-5.84h39.477a5.849 5.849 0 015.84 5.84V7.78a1.52 1.52 0 01-1.5 1.518z"/>
      <filter id="svg-enjoy-h" width="127.4%" height="125.5%" x="-13.7%" y="-9.1%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
      </filter>
    </defs>
    <g fill="none">
      <path fill="#B2B2B2" d="M16.635 36.556H11.88c-.839 0-1.519-.68-1.519-1.519V19.945c0-.839.68-1.518 1.519-1.518h4.214a5.02 5.02 0 013.92 8.153 5.56 5.56 0 01-3.379 9.976zm-3.234-3.037h3.234a2.527 2.527 0 100-5.05h-3.234v5.05zm0-8.087h2.693a1.986 1.986 0 000-3.969h-2.693v3.97zM52.86 36.528c-.839 0-1.519-.68-1.519-1.518v-2.639c0-.668-.458-3.037-1.913-3.037h-3.316v5.67a1.518 1.518 0 01-3.037 0V19.98c0-.838.68-1.518 1.519-1.518h4.858a5.442 5.442 0 013.477 9.63c.195.253.369.522.52.804a7.914 7.914 0 01.91 3.483v2.64a1.518 1.518 0 01-1.5 1.508zm-3.432-10.224h.043a2.408 2.408 0 100-4.813h-3.34v4.813h3.297zm-10.85 10.054a1.518 1.518 0 01-1.394-.91l-1.882-4.374h-6.393l-1.898 4.38a1.52 1.52 0 11-2.787-1.215l2.168-5.002a1.5 1.5 0 01.243-.555l3.899-9.004a1.716 1.716 0 011.588-1.054c.694 0 1.32.418 1.585 1.06l6.265 14.57a1.518 1.518 0 01-1.394 2.126v-.022zm-8.354-8.33h3.772l-1.88-4.363-1.892 4.364z" filter="url(#a)" transform="translate(12 13)"/>
      <g transform="translate(12 13)">
        <use fill="#000" filter="url(#svg-enjoy-b)" href="#svg-enjoy-c"/>
        <use fill="#ADAFB0" href="#svg-enjoy-c"/>
      </g>
      <path fill="#FFAB00" d="M61.93 43.583H4.206a3.926 3.926 0 01-3.924-3.924V15.323A3.926 3.926 0 014.206 11.4H61.93a3.926 3.926 0 013.92 3.923V28.94a1.518 1.518 0 01-3.036 0V15.323a.887.887 0 00-.887-.887H4.206a.887.887 0 00-.887.887V39.66c0 .49.397.887.887.887H61.93c.49 0 .887-.397.887-.887v-4.105a1.518 1.518 0 113.037 0v4.105a3.926 3.926 0 01-3.924 3.924z" filter="url(#d)" transform="translate(12 13)"/>
      <g transform="translate(12 13)">
        <use fill="#000" filter="url(#svg-enjoy-e)" href="#svg-enjoy-f"/>
        <use fill="#FFAB00" href="#svg-enjoy-f"/>
      </g>
      <path fill="#EB2D62" d="M52.811 55.04H13.316a5.849 5.849 0 01-5.84-5.84v-1.86a1.518 1.518 0 113.037 0v1.86a2.806 2.806 0 002.803 2.803h39.477a2.806 2.806 0 002.803-2.803v-1.86a1.518 1.518 0 113.036 0v1.86a5.849 5.849 0 01-5.82 5.84zm4.321-45.742c-.838 0-1.518-.68-1.518-1.518V5.919a2.806 2.806 0 00-2.803-2.803H13.316a2.806 2.806 0 00-2.803 2.803V7.78a1.518 1.518 0 11-3.037 0V5.919a5.849 5.849 0 015.84-5.84h39.477a5.849 5.849 0 015.84 5.84V7.78a1.52 1.52 0 01-1.5 1.518z" filter="url(#g)" transform="translate(12 13)"/>
      <g transform="translate(12 13)">
        <use fill="#000" filter="url(#svg-enjoy-h)" href="#svg-enjoy-i"/>
        <use fill="#EB2D62" href="#svg-enjoy-i"/>
      </g>
      <path fill="#C9CCCE" d="M28.635 48.772H23.88a.835.835 0 01-.836-.835.125.125 0 000-.055V32.854c0-.461.374-.835.836-.835h4.214a4.336 4.336 0 012.864 7.592 4.88 4.88 0 01-2.323 9.17v-.009zm-3.92-1.67h3.92a3.21 3.21 0 000-6.42h-3.926l.006 6.42zm0-8.09h3.38a2.666 2.666 0 000-5.332h-3.386l.006 5.332zm40.145 9.733a.835.835 0 01-.835-.835v-2.63c0-.993-.608-3.717-2.597-3.717h-3.996v6.356a.835.835 0 11-1.67 0V32.88c0-.461.374-.835.835-.835h4.859a4.758 4.758 0 012.453 8.837c.38.358.698.777.942 1.239.516.974.8 2.055.829 3.158v2.639a.835.835 0 01-.82.835v-.01zm-3.432-8.861h.043a3.088 3.088 0 000-6.177h-4.04v6.177h3.997zm-10.85 8.69a.835.835 0 01-.765-.506l-2.062-4.762h-7.288l-2.09 4.774a.835.835 0 11-1.518-.665l2.199-5.075a.835.835 0 01.179-.41l3.948-9.082a1.02 1.02 0 01.953-.632c.428-.002.812.26.966.66l6.259 14.542a.835.835 0 01-.78 1.166v-.01zm-9.395-6.962h5.851L44.12 34.84l-2.936 6.772zm2.36-8.111v.033-.033zm1.168-.025l-.018.04.018-.04z"/>
      <path fill="#FEFE00" d="M73.93 55.808H16.206a3.243 3.243 0 01-3.24-3.24V28.232a3.246 3.246 0 013.24-3.243H73.93a3.246 3.246 0 013.24 3.243v13.617a.835.835 0 01-1.67 0V28.232c0-.868-.702-1.571-1.57-1.573H16.206a1.573 1.573 0 00-1.57 1.573v24.336a1.573 1.573 0 001.57 1.57H73.93a1.573 1.573 0 001.57-1.57v-4.1a.835.835 0 011.67 0v4.1a3.243 3.243 0 01-3.24 3.24z" opacity=".5"/>
      <path fill="#FF6376" d="M64.811 67.266H25.316a5.162 5.162 0 01-5.162-5.163v-1.861a.835.835 0 011.67 0v1.861a3.49 3.49 0 003.492 3.492h39.477a3.49 3.49 0 003.486-3.486v-1.861a.835.835 0 011.67 0v1.861a5.162 5.162 0 01-5.138 5.157zm4.321-45.742a.835.835 0 01-.835-.835v-1.862a3.49 3.49 0 00-3.486-3.486H25.316a3.49 3.49 0 00-3.486 3.486v1.862a.835.835 0 11-1.67 0v-1.862a5.162 5.162 0 015.162-5.162h39.477a5.162 5.162 0 015.162 5.162v1.862c0 .459-.37.832-.829.835z"/>
      <g>
        <path fill="#F2F3F3" d="M28.656 48.216h-4.752a.304.304 0 01-.304-.303V32.817c0-.167.136-.303.304-.303h4.212a3.808 3.808 0 011.761 7.182 4.349 4.349 0 01-1.215 8.52h-.006zm-4.449-.607h4.45a3.741 3.741 0 000-7.482h-4.45v7.482zm0-8.09h3.909a3.2 3.2 0 000-6.398h-3.909v6.398zm40.674 8.67a.304.304 0 01-.304-.304v-2.638c0-.845-.555-4.252-3.128-4.252h-4.524v6.887a.304.304 0 01-.608 0V32.845c0-.168.136-.304.304-.304h4.859a4.23 4.23 0 011.421 8.2c1.667.91 2.268 3.276 2.268 4.493v2.64a.304.304 0 01-.276.315h-.012zm-3.429-7.798h.046a3.623 3.623 0 000-7.243h-4.555v7.243h4.51zm-10.847 7.625a.304.304 0 01-.279-.182l-2.199-5.108h-7.992l-2.214 5.108a.305.305 0 01-.559-.243l2.238-5.162c0-.09.04-.174.107-.231l3.972-9.168a.498.498 0 01.467-.304.498.498 0 01.471.322l6.265 14.558a.304.304 0 01-.158.398.276.276 0 01-.11.012h-.009zM40.4 42.12h7.467l-3.723-8.643L40.4 42.12z"/>
        <path fill="#FEFE00" d="M73.964 55.243H16.239a2.712 2.712 0 01-2.709-2.708v-24.34a2.712 2.712 0 012.71-2.708h57.724a2.715 2.715 0 012.711 2.709v13.62a.304.304 0 11-.607 0v-13.62a2.107 2.107 0 00-2.104-2.102H16.239a2.107 2.107 0 00-2.101 2.102v24.339a2.107 2.107 0 002.101 2.101h57.725a2.107 2.107 0 002.104-2.101v-4.109a.304.304 0 01.607 0v4.109a2.715 2.715 0 01-2.711 2.708z"/>
        <path fill="#FF8E8F" d="M64.844 66.689H25.368a4.628 4.628 0 01-4.625-4.625v-1.862a.304.304 0 01.607 0v1.862a4.02 4.02 0 004.011 4.017h39.477a4.02 4.02 0 004.018-4.017v-1.862a.304.304 0 01.607 0v1.862a4.628 4.628 0 01-4.619 4.625zm4.322-45.739a.304.304 0 01-.304-.304v-1.861a4.02 4.02 0 00-4.018-4.018H25.368a4.02 4.02 0 00-4.018 4.018v1.861a.304.304 0 01-.607 0v-1.861a4.628 4.628 0 014.625-4.625h39.476a4.628 4.628 0 014.625 4.625v1.861a.304.304 0 01-.303.313v-.009z"/>
      </g>
    </g>
  </svg>;

  export default Enjoy;